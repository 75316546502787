import React, { useState } from 'react';
import { userServices } from '../../services/userServices';
import './passwordreset.css';

const PasswordReset = () => {
    const [mobile, setmobile] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [showOtpForm, setShowOtpForm] = useState(false);

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        if (!mobile || !/^[0-9]{10}$/.test(mobile)) {
                    setMessage('Please enter a valid 10-digit mobile number');
                    return;
                }
        
        try {
            const response = await userServices.forgotPassword(mobile);
            setMessage(response.message);
            setShowOtpForm(true);
        } catch (error) {
            setMessage(error.response?.data?.message || 'An error occurred');
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword.length < 5) {
            setMessage('Password must be at least 5 characters long');
            return;        }
        try {
            const response = await userServices.resetPassword(mobile, otp, newPassword);
            setMessage(response.message);
            window.location.href='/';
        } catch (error) {
            setMessage(error.response?.data?.message || 'An error occurred');
        }
    };

    return (
        <div className="password-reset-container">
            <h2>Password Reset</h2>
            {!showOtpForm && (
                <form onSubmit={handleForgotPassword}>
                    <h3>Forgot Password</h3>
                    <input
                        type="mobile"
                        value={mobile}
                        onChange={(e) => setmobile(e.target.value)}
                        placeholder="Enter your mobile number"
                        required
                    />
                    <button type="submit">Send OTP</button>
                </form>
            )}
            {showOtpForm && (
                <form onSubmit={handleResetPassword}>
                    <h3>Reset Password</h3>
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        required
                    />
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter new password (min 5 chars, 1 letter)"
                        required
                    />
                    <button type="submit">Reset Password</button>
                </form>
            )}
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default PasswordReset;