import axios from 'axios';
import { API_URL } from '../config/api.config';

export const adminServices = {
  checkAdminAccess: async (token) => {
    const response = await axios.post(`${API_URL}/check_admin_access`, null, {
      params: { token }
    });
    return response.data;
  },

  getAllProfiles: async () => {
    const response = await axios.get(`${API_URL}/get_all_profiles`);
    return response.data;
  },
  getMatches: async () => {
    const response = await axios.get(`${API_URL}/get_matches`);
    return response.data;
  },

  getAllUsers: async () => {
    const response = await axios.get(`${API_URL}/get_all_users`);
    return response.data;
  },

  deleteUser: async (userId) => {
    const response = await axios.delete(`${API_URL}/delete_user/${userId}`);
    return response.data;
  },

  updateProfile: async (userData) => {
    const response = await axios.put(`${API_URL}/update_profile/${userData.profile_id}`, userData);
    return response.data;
  },

  deleteProfile: async (profileId) => {
    const response = await axios.delete(`${API_URL}/delete_profile/${profileId}`);
    return response.data;
  },

  updateMatch: async (match_id, status) => {
    const response = await axios.put(`${API_URL}/update_match_status`, {
       match_id,status
    });
    return response.data;
  },

  deleteMatch: async (matchId) => {
    const response = await axios.delete(`${API_URL}/delete_match/${matchId}`);
    return response.data;
  },

  getSupportRequests: async () => {
    const response = await axios.get(`${API_URL}/get_all_support_requests`);
    return response.data;
  },

  deleteSupportRequest: async (requestId) => {
    const response = await axios.delete(`${API_URL}/delete_support_request/${requestId}`);
    return response.data;
  },
};
