import axios from 'axios';
import { API_URL } from '../config/api.config';

export const photoServices = {
  uploadPhoto: async (userId, photoFile) => {
    const formData = new FormData();
    formData.append('photo', photoFile);
    const response = await axios.post(`${API_URL}/upload_photo?user_id=${userId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  },

  getAllPhotos: async (userId) => {
    const response = await axios.get(`${API_URL}/get_all_photos/${userId}`);
    return response.data;
  }
};
