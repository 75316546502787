import React, { useState, useEffect } from 'react';
import styles from './RishtaJunction.module.css';
import icon4 from './../assets/icon4.svg';
import icon9 from './../assets/icon9.svg';
import icon1 from './../assets/icon1.svg';
import icon6 from './../assets/icon6.svg';
import icon8 from './../assets/icon8.svg';
import icon2 from './../assets/icon2.svg';
import Register from '../pages/register/register';
import { adminServices } from '../services/adminServices';

const Hero = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        const accessToken = localStorage.getItem('accessToken');
        setIsLoggedIn(!!userId && !!accessToken);
    }, []);

    useEffect(() => {
        const checkAdminStatus = async () => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                try {
                    const response = await adminServices.checkAdminAccess(token);
                    setIsAdmin(response.success === true);
                } catch (error) {
                    setIsAdmin(false);
                }
            }
        };
        checkAdminStatus();
    }, []);

    const statsData = [
        { imgSrc: icon4, number: "Live Now", description: "Trusted profiles are available on our platform." },
        { imgSrc: icon9, number: "Join Now", description: "Be among the first to find your perfect match." },        { imgSrc: icon1, number: "24/7", description: "Dedicated support to assist you at any time." },
        { imgSrc: icon6, number: "User Friendly", description: "Easy navigation to find your ideal partner." },
        { imgSrc: icon8, number: "Verified Matches", description: "All profiles are thoroughly verified for authenticity." },
        { imgSrc: icon2, number: "Safe & Secure", description: "Your privacy is our utmost priority." }
    ];

    return (
        <section className={`${styles.hero} ${styles.heroBackground}`}>
            <div className={styles.heroContainer}>
                
            <div className={styles.welcometext}>
            <h2 className={styles.heroTitle}>Start your happy journey with your soulmate
            </h2>
                <p className={styles.heroSubtitle}>
                    In our country, marriage is not just a ceremony, but a sacred bond.
                    <br />
                    Join us to find your perfect match today.
                </p>
                
                <button className={styles.ctaButton} onClick={() => window.location.href = '/'}>Register Now for Free</button>
            </div>
            {!isLoggedIn ? (
                <div className={styles.registerForm}>
                    <Register />
                </div>
            ) : (
                <div className={styles.loggedinContainer}>
                    <h3>Welcome to Rishta Junction!</h3>
                    <div className={styles.userOptions}>
                        {isAdmin && <button onClick={() => window.location.href = '/admin'}>Admin Panel</button>}
                        <button onClick={() => window.location.href = '/profile'}>Edit your Profile</button>
                        <button onClick={() => window.location.href = '/search'}>Find Matches</button>
                        <button onClick={() => window.location.href = '/support'}>Help</button>
                    </div>
                </div>
            )}
            </div>
           
            <div className={styles.stats}>
                {statsData.map((stat, index) => (
                    <div key={index} className={styles.statItem}>
                        <img src={stat.imgSrc} alt={stat.description} className={styles.statIcon} />
                        <span className={styles.statNumber}>{stat.number}</span>
                        <span className={styles.statLabel}>{stat.description}</span>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Hero;