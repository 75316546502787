import React, { useState, useEffect } from 'react';
import './search.css';
import { profileServices } from '../../services/profileServices';
import { photoServices } from '../../services/photoServices';


const Search = () => {
  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    const accessToken = localStorage.getItem('accessToken');
    
    if (!userId || !accessToken) {
      window.location.href = '/';
    }
  }, []);

  const [searchCriteria, setSearchCriteria] = useState({
    age_min: '',
    age_max: '',
    gender: '',
    location: '',
    religion: '',
    caste: '',
    profession: ''
  });
  
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userPhotos, setUserPhotos] = useState({});
  const resultsPerPage = 5;



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if at least one filter is selected
    if (Object.values(searchCriteria).every(value => value === '')) {
      alert('Please select at least one filter');
      return;
    }
    try {
      const filteredCriteria = Object.fromEntries(
        Object.entries(searchCriteria).filter(([_, value]) => value !== '')
      );
      const data = await profileServices.searchProfiles(filteredCriteria);
      setSearchResults(data);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching search results:', error);
      alert('An error occurred while fetching search results');
    }
  };

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = searchResults.slice(indexOfFirstResult, indexOfLastResult);

  
  // useEffect(() => {
  //   currentResults.forEach(result => {
  //     fetchUserPhotos(result.user_id);
  //   });
  // }, [currentResults]);
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const createMatch = async (profileId) => {
    try {
      let userId = localStorage.getItem("user_id");
      await profileServices.createMatch(userId, profileId);
      alert('Match created successfully!');
      window.location.href = `/userprofile/${profileId}`;
    } catch (error) {
      console.error('Error creating match:', error);
      alert('An error occurred while creating the match');
    }
  };

  const fetchUserPhotos = async (userId) => {
    try {
      const photos = await photoServices.getAllPhotos(userId);
      if (photos && photos.length > 0) {
        setUserPhotos(prev => ({
          ...prev,
          [userId]: photos[0].image_data // Getting first photo as display photo
        }));
      }
    } catch (error) {
      console.error('Error fetching user photo:', error);
    }
  };

  return (
    <div className="search-container">
      <h2>Search Potential Matches</h2>

      <form onSubmit={handleSubmit} className="search-form">
        <div className="search-row">
          <div className="search-field">
            <label htmlFor="age_min">Min Age</label>
            <select id="age_min" name="age_min" value={searchCriteria.age_min} onChange={handleInputChange}>
              <option value="">Select Min Age</option>
              {[...Array(43)].map((_, i) => (
                <option key={i} value={i + 18}>{i + 18}</option>
              ))}
            </select>
          </div>
          <div className="search-field">
            <label htmlFor="age_max">Max Age</label>
            <select id="age_max" name="age_max" value={searchCriteria.age_max} onChange={handleInputChange}>
              <option value="">Select Max Age</option>
              {[...Array(40)].map((_, i) => (
                <option key={i} value={i + 21} disabled={parseInt(searchCriteria.age_min) >= (i + 21)}>{i + 21}</option>
              ))}
            </select>
          </div>
          <div className="search-field">
            <label htmlFor="gender">Gender</label>
            <select id="gender" name="gender" value={searchCriteria.gender} onChange={handleInputChange}>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="search-field">
            <label htmlFor="location">Location</label>
            <input type="text" id="location" name="location" value={searchCriteria.location} onChange={handleInputChange} />
          </div>
          <div className="search-field">
            <label htmlFor="religion">Religion</label>
            <select id="religion" name="religion" value={searchCriteria.religion} onChange={handleInputChange}>
              <option value="Hindu">Hindu</option>
              <option value="Muslim">Muslim</option>
              <option value="Christian">Christian</option>
              <option value="Sikh">Sikh</option>
              <option value="Jain">Jain</option>
              <option value="Buddhist">Buddhist</option>
              <option value="Jewish">Jewish</option>
              <option value="Zoroastrian">Zoroastrian</option>
              <option value="Bahai">Baha'i</option>
              <option value="Atheist">Atheist</option>
              <option value="Agnostic">Agnostic</option>
              <option value="">Other</option>
            </select>
          </div>


          <div className="search-field">
            <label htmlFor="profession">Profession</label>
            <select id="profession" name="profession" value={searchCriteria.profession} onChange={handleInputChange}>
              <option value="">Select Profession</option>
              <option value="engineer">Engineer</option>
              <option value="doctor">Doctor</option>
              <option value="teacher">Teacher</option>
              <option value="lawyer">Lawyer</option>
              <option value="accountant">Accountant</option>
              <option value="manager">Manager</option>
              <option value="entrepreneur">Entrepreneur</option>
              <option value="artist">Artist</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>
        <button type="submit" className="search-button">Search</button>
      </form>

      <div className="search-results">
  {currentResults.map(result => (
    <div key={result.id} className="search-result-item">
      <div className="search-result-photo">
        {userPhotos[result.user_id] ? (
          <img 
            src={`data:image/jpeg;base64,${userPhotos[result.user_id]}`}
            alt={result.name}
            className="result-user-photo"
          />
        ) : (
          <div className="photo-placeholder">
            {result.name[0].toUpperCase()}
          </div>
        )}
      </div>
      <div className="search-result-content">
        <a href={`/userprofile/${result.user_id}`}><h4>{result.name}</h4></a>
        <p>Age: {result.age} | Gender: {result.gender}</p>
        <p>Location: {result.location}</p>
        <p>Religion: {result.religion} | Caste: {result.caste}</p>
        <p>Profession: {result.profession}</p>
      </div>
      <div className='search-result-buttons'>
        <button onClick={() => window.location.href = `/userprofile/${result.user_id}`} className="view-profile-button">View Profile</button>
        <button onClick={() => createMatch(result.user_id)} className="create-match-button">Create Match</button>
      </div>
    </div>
  ))}
</div>



      {searchResults.length > resultsPerPage && (
        <div className="search-pagination">
          <button
            className="pagination-arrow"
            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
          >
            ←
          </button>

          {Array.from({ length: Math.ceil(searchResults.length / resultsPerPage) }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              className={currentPage === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </button>
          ))}
          <button
            className="pagination-arrow"
            onClick={() => paginate(currentPage < Math.ceil(searchResults.length / resultsPerPage) ? currentPage + 1 : currentPage)}
          >
            →
          </button>
        </div>
      )}
    </div>
  );
};

export default Search;