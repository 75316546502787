import React from 'react';
import styles from './RishtaJunction.module.css';
import img1 from './../assets/profilebackground.jpeg';
import img2 from './../assets/rishtapamplet.jpeg';

import img6 from './../assets/img6.jpeg';

const Partners = () => {
  
  return (
    <section className={styles.partners}>
      
      <div className={styles.customerGrid}>
      <img src={img1} alt="Customer 1" className={styles.customerImage} />
      <img src={img2} alt="Customer 2" className={styles.customerImage} />
      <img src={img6} alt="Customer 3" className={styles.customerImage} />
      </div>
    </section>
  );
};

export default Partners;