import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Select } from 'antd';
import { adminServices } from '../../services/adminServices';
import './admin.css';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [profiles, setProfile] = useState([]);
  const [matches, setMatches] = useState([]);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [isMatchModalVisible, setIsMatchModalVisible] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [supportRequests, setSupportRequests] = useState([]);


  useEffect(() => {
    fetchUsers();
    fetchProfiles();
    fetchMatches();
    fetchSupportRequests();
  }, []);

  useEffect(() => {
    const verifyAdminAccess = async () => {
      const token = localStorage.getItem('accessToken');
      try {
        await adminServices.checkAdminAccess(token);
      } catch (error) {
        message.error('Unauthorized access');
        window.location.href = '/';
      }
    };
    verifyAdminAccess();
  }, []);

  //fetching data functions
  const fetchUsers = async () => {
    try {
      const data = await adminServices.getAllUsers();
      setUsers(data);
    } catch (error) {
      message.error('Failed to fetch users');
    }
  };

  const fetchProfiles = async () => {
    try {
      const data = await adminServices.getAllProfiles();
      setProfile(data);
    } catch (error) {
      message.error('Failed to fetch profiles');
    }
  };

  const fetchMatches = async () => {
    try {
      const response = await adminServices.getMatches();
      setMatches(response);
    } catch (error) {
      message.error('Failed to fetch matches');
    }
  };

  const fetchSupportRequests = async () => {
    try {
      const data = await adminServices.getSupportRequests();
      setSupportRequests(data);
    } catch (error) {
      message.error('Failed to fetch support requests');
    }
  };

  //data manipulation functions
  const handleDeleteUser = async (user_id) => {
    try {
      await adminServices.deleteUser(user_id);
      message.success('User deleted successfully');
      fetchUsers();
    } catch (error) {
      message.error('Failed to delete User');
    }
  };

  const handleUpdateProfile = async (values) => {
    try {
      await adminServices.updateProfile(values);
      message.success('Profile updated successfully');
      setIsProfileModalVisible(false);
      fetchProfiles();
    } catch (error) {
      message.error('Failed to update profile');
    }
  };

  const handleDeleteProfile = async (profile_id) => {
    try {
      await adminServices.deleteProfile(profile_id);
      message.success('Profile deleted successfully');
      fetchProfiles();
    } catch (error) {
      message.error('Failed to delete profile');
    }
  };

  const handleUpdateMatch = async (values) => {
    try {
      await adminServices.updateMatch(currentMatch.match_id, values.status);
      message.success('Match updated successfully');
      setIsMatchModalVisible(false);
      fetchMatches();
    } catch (error) {
      message.error('Failed to update match');
    }
  };

  const handleDeleteMatch = async (record) => {
    try {
      await adminServices.deleteMatch(record.match_id);
      message.success('Match deleted successfully');
      fetchMatches();
    } catch (error) {
      message.error('Failed to delete match');
    }
  };

  const handleDeleteSupportRequest = async (requestId) => {
    try {
      await adminServices.deleteSupportRequest(requestId);
      message.success('Support request deleted successfully');
      fetchSupportRequests();
    } catch (error) {
      message.error('Failed to delete support request');
    }
  };

  //columns to display 
  const userColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    { title: 'Name', dataIndex: 'name', key: 'name',
      render: (text, record) => (
      <a href={`/userprofile/${record.user_id}`} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ) },
    { title: 'Mobile', dataIndex: 'mobile_number', key: 'mobile_number' },
    { title: 'User type', dataIndex: 'user_type', key: 'user_type' },
    { title: 'Looking for', dataIndex: 'looking_for', key: 'looking_for' },

    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString()
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          className="action-button"
          onClick={() => handleDeleteUser(record.user_id)}
          danger
        >
          Delete
        </Button>
      ),
    },
  ];

  const profileColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Gender', dataIndex: 'gender', key: 'gender' },
    { title: 'Age', dataIndex: 'age', key: 'age' },
    { title: 'Religion', dataIndex: 'religion', key: 'religion' },

    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button className="action-button" onClick={() => { setCurrentProfile(record); setIsProfileModalVisible(true); }}>Update</Button>
          <Button className="action-button" onClick={() => handleDeleteProfile(record.profile_id)} danger>Delete</Button>
        </>
      ),
    },
  ];

  const matchColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    { title: 'Name', dataIndex: 'matcher_name', key: 'matcher_name' },
    { title: 'Matched Name', dataIndex: 'matched_user_name', key: 'matched_user_name' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button className="action-button" onClick={() => { setCurrentMatch(record); setIsMatchModalVisible(true); }}>Update</Button>
          <Button className="action-button" onClick={() => handleDeleteMatch(record)} danger>Delete</Button>
        </>
      ),
    },
  ];

  const supportRequestColumns = [
    {
      title: 'Sr.No',
      key: 'serialNumber',
      render: (_, __, index) => index + 1,
    },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Mobile', dataIndex: 'mobile_number', key: 'mobile_number' },
    { title: 'Request', dataIndex: 'request_text', key: 'request_text' },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString()
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          className="action-button"
          onClick={() => handleDeleteSupportRequest(record.request_id)}
          danger
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="admin-container">
      <h1 className="admin-title">Admin Dashboard</h1>

      <h2 className="section-title">Users</h2>
      <Table dataSource={users} columns={userColumns} rowKey="user_id"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          responsive: true,
          size: "small"
        }} />

      <h2 className="section-title">Profiles</h2>
      <Table dataSource={profiles} columns={profileColumns} rowKey="profile_id" scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          responsive: true,
          size: "small"
        }} />

      <h2 className="section-title">Matches</h2>
      <Table dataSource={matches} columns={matchColumns} rowKey="matcher_id" scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          responsive: true,
          size: "small"
        }} />

      <h2 className="section-title">Support Requests</h2>
      <Table
        dataSource={supportRequests}
        columns={supportRequestColumns}
        rowKey="request_id"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          responsive: true,
          size: "small"
        }}
      />

      <Modal
        title="Update Profile"
        visible={isProfileModalVisible}
        onCancel={() => setIsProfileModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleUpdateProfile} initialValues={currentProfile}>
          <Form.Item name="profile_id" label="Profile ID">
            <Input contentEditable={false} />
          </Form.Item>
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ type: 'email' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="gender" label="Gender">
            <Input />
          </Form.Item>
          <Form.Item name="age" label="Age">
            <Input type="number" />
          </Form.Item>

          <Form.Item name="residence" label="Residence">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Update</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Match"
        visible={isMatchModalVisible}
        onCancel={() => setIsMatchModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleUpdateMatch} initialValues={currentMatch}>
          <Form.Item name="matched_user_id" label="Matched User ID" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select>
              <Select.Option value="new">New</Select.Option>
              <Select.Option value="contacted">Contacted</Select.Option>
              <Select.Option value="pending">Pending</Select.Option>
              <Select.Option value="matched">Matched</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">Update</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminPage;